
const product_card=[
    {
        id:1,
        product_name:'jacket',
        brandname:'zivame',
        seller:'roman logistics',
        thumb:'.images/home-img.png',
        logic:'6 US',
        description: 'best quality',
        momories1:'.images/home-img.png',
        momories2:'.images/home-img.png',
        momories3:'.images/home-img.png',
        momories4:'.images/home-img.png',
        brought_by:'@bidder',
        time_broght:'1 day ago',
        minted_by:'@sidco',
        Minted_time:'2 days ago'

    },
    {
        id:2,
        product_name:'jeans',
        brandname:'lewis',
        seller:'madhav enterprises',
        thumb:'.images/home-img.png',
        logic:'6 US',
        description: 'best quality',
        momories1:'.images/home-img.png',
        momories2:'.images/home-img.png',
        momories3:'.images/home-img.png',
        momories4:'.images/home-img.png',
        brought_by:'@bidder',
        time_broght:'1 day ago',
        minted_by:'@sidco',
        Minted_time:'2 days ago'

    },
    {
        id:3,
        product_name:'top',
        brandname:'zivame',
        seller:'roman logistics',
        thumb:'.images/home-img.png',
        logic:'6 US',
        description: 'best quality',
        momories1:'.images/home-img.png',
        momories2:'.images/home-img.png',
        momories3:'.images/home-img.png',
        momories4:'.images/home-img.png',
        brought_by:'@bidder',
        time_broght:'1 day ago',
        minted_by:'@sidco',
        Minted_time:'2 days ago'

    }
   
]


export default product_card